import React from "react"
import Contact from "templates/contact"
import { graphql } from "gatsby"
import { getFieldLang } from "utils"

export default (props) => {
  const {
    data: {
      allDatoCmsContact
    },
    pageContext: {
      lang,
      slugs
    }
  } = props;

  const data = allDatoCmsContact && allDatoCmsContact.edges[0].node;

  return (
    <div>
      {data &&
        <Contact
          data={{ 
            ...data,
            informationComplementaire: data[`informationComplementaire${lang}`],
            contactNomInputs: data[`contactNomInputs${lang}`],
            reponseContactOk: data[`reponseContactOk${ lang }`],
            reponseContactKo: data[`reponseContactKo${ lang }`],
          }}
          location={{ pathname: '/' }}
          currentPage={ props.location.pathname }
          lang={lang}
          slugs={slugs}
        />
      }
    </div>
  );
};

export const query = graphql`
  query ContactQuery($website: String!) {
    allDatoCmsContact(filter: {website: {eq: $website}}) {
      edges {
        node {
          illustrationContact {
            id
            fluid(maxWidth: 1920, imgixParams: {fm: "jpg", auto: "compress"}) {
              ...GatsbyDatoCmsFluid
            }
          }
          informationComplementaireFr
          informationComplementaireEn
          informationComplementaireIt
          informationComplementaireDe
          reponseContactOkFr
          reponseContactOkEn
          reponseContactOkIt
          reponseContactOkDe
          reponseContactKoFr
          reponseContactKoEn
          reponseContactKoIt
          reponseContactKoDe
          contactNomInputsFr {
            arrive
            depart
            adult
            child
            name
            address
            tel
            mail
            yourmessage
            send
          }
          contactNomInputsEn {
            arrive
            depart
            adult
            child
            name
            address
            tel
            mail
            yourmessage
            send
          }
          contactNomInputsIt {
            arrive
            depart
            adult
            child
            name
            address
            tel
            mail
            yourmessage
            send
          }
          contactNomInputsDe {
            arrive
            depart
            adult
            child
            name
            address
            tel
            mail
            yourmessage
            send
          }
        }
      }
    }
  }
`